import { useRouter } from "next/router";
import { milestones } from "src/data/milestones";
import { Box } from "@xstyled/styled-components";
import { Container, FlexBox, GridBox } from "@/UI/Containers";
import { LandingTitle, Text } from "@/UI/Text";
import GradientButtonYellow from "@/UI/buttons/GradientButtonYellow";
import MintSection from "@/UI/footer/MintSection";
import Icon from "@/UI/Icon";
import { DiscordTransparent } from "@/UI/icons";

export default function RoadPlan() {
  const router = useRouter();
  return (
    <Box
      as="section"
      id="roadmap"
      position="relative"
      background="#05151b"
      pt={{ desktop: 150 }}
      overflow="hidden"
    >
      <Container
        data-aos="fade-up"
        p={{ _: "2rem 1rem", desktop: "0 0 6rem 0" }}
        zIndex={3}
      >
        <Text mb="1rem" size="1.25rem" fontFamily="Lufga-Regular">
          PREPARE YOURSELF FOR AN EXTRAORDINARY JOURNEY!
        </Text>
        <LandingTitle mt={0} text="Our Road Trip Plan" />
        <Text
          size={{ _: "1rem", desktop: "1.25rem" }}
          max={850}
          mt={{ _: -16, desktop: -24 }}
        >
          Our main destination is launching the{" "}
          <Box
            as="a"
            href="https://supplain.io"
            target="_blank"
            rel="noreferrer noopener"
            fontFamily="Lufga-Bold"
            textDecoration="underline"
            color="#FEB806"
            hoverColor="#fff"
          >
            Supplain
          </Box>{" "}
          protocol. <br />
          However, we have planned some fun pit stops for our freight frens
          along the ride. <br />
          Once we hit a target sell-through percentage, we will add these to our
          itinerary.
        </Text>
        <FlexBox dir="column" maxW={800} align="flex-start">
          {milestones.map(({ percent, done, text }) => {
            return (
              <GridBox key={percent} cols="102px 1fr" alignItems="center">
                <Box
                  as="span"
                  fontSize="1.625rem"
                  color={done ? "#FEB806" : "#909090"}
                >
                  {percent}
                </Box>
                <Text
                  my={{ _: "0.6rem", xl_desktop: "1rem" }}
                  size="1rem"
                  color={done ? "#FEB806" : "#FFFFF"}
                  textDecoration={done ? "line-through" : "none"}
                >
                  {text}
                </Text>
              </GridBox>
            );
          })}
        </FlexBox>
        <Box
          as="img"
          alt="Space light"
          src="/assets/space_light.png"
          display={{ _: "none", desktop: "inline" }}
          position="absolute"
          top="-10%"
          right="-40%"
          zIndex={3}
          m={0}
        />
        <FlexBox
          dir={{ _: "column", desktop: "row" }}
          bg="#292F3C"
          p={{ _: "40px 30px", desktop: "50px 70px" }}
          borderRadius={10}
          mt={{ _: "16rem", sm_tablet: "20rem", desktop: "22rem" }}
          position="relative"
        >
          <Icon
            aria="Discord icon"
            position="absolute"
            top={{ _: "5%", desktop: "10%" }}
            right={{ _: "5%", desktop: "23%" }}
            transform="scale(0.8) translateY(10%)"
          >
            <DiscordTransparent />
          </Icon>
          <FlexBox
            dir="column"
            w={{ desktop: "80%" }}
            align="flex-start"
            justify="flex-start"
          >
            <Text
              m={0}
              maxW={370}
              lineHeight={1.2}
              size={{ _: "1.5625rem", desktop: "1.9375rem" }}
              font="Semibold"
            >
              JOIN THE COMMUNITY OF FREIGHT FRENS
            </Text>
            <Text m={0} mt={10} size="1rem" font="Regular">
              Come chat with us and the rest of the community via our Official
              Discord server.
            </Text>
          </FlexBox>
          <GradientButtonYellow
            onClick={() => router.push("https://discord.gg/WorldOfFreight")}
            fontSize="1rem"
            letterSpacing={0.5}
            color="#2B2B2B"
            p="20px 40px"
            mt={{ _: "2rem", desktop: 0 }}
          >
            Discord
          </GradientButtonYellow>
        </FlexBox>
        <MintSection
          transform={{ _: "translateY(30%)", desktop: "translateY(80%)" }}
        />
      </Container>
      <Box
        as="img"
        alt="Space race"
        src="/assets/backgrounds/bg_space_race.webp"
        position="absolute"
        transform={{ _: "scale(1.8)", desktop: "scale(1.1)" }}
        bottom={{
          _: "24%",
          tablet: "20%",
          desktop: "18%",
          lg_desktop: "16%",
          xxl_desktop: "6%",
        }}
        left={{ _: "-20%", desktop: 0 }}
        zIndex={1}
      />
    </Box>
  );
}
