import getRaceCargoWeight from "src/utils/racing/getRaceCargoWeight";
import styled, { Box } from "@xstyled/styled-components";
import Icon from "@/UI/Icon";
import {
  Foggy,
  Icy,
  Rainy,
  Snowy,
  Stormy,
  Sunny,
  Windy,
} from "@/UI/icons/RacingArena";

const OverviewElement = styled.divBox`
  width: max-content;
  max-height: 40px;
  padding: 10px 24px;
  border-radius: 27px;
  background: #292f3c;
`;

const DefinitionList = styled.dl`
  display: inline-flex;
  margin: 0;

  dd {
    margin: 0;
  }

  dt {
    margin-right: 10px;
    color: #a8a8a8;
  }

  dd {
    font-family: Lufga-Semibold;
  }
`;

const MapMarker = styled(Icon)`
  margin-right: 6px;

  span {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
`;

export const Coordinates = ({ from, to }) => {
  const isSingleLocation = from.includes("last mile delivery");
  const fromLocation = isSingleLocation
    ? from.split(" last mile delivery")[0]
    : from;

  return (
    <OverviewElement spaceX={12}>
      <DefinitionList>
        <dt>From: </dt>
        <dd>
          <MapMarker aria="Race starting point">
            <Box as="span" bg="#D00202" />
          </MapMarker>
          {fromLocation}
        </dd>
      </DefinitionList>
      <DefinitionList>
        <dt>To: </dt>
        <dd>
          <MapMarker aria="Race finish">
            <Box as="span" bg={isSingleLocation ? "#D00202" : "#02D07E"} />
          </MapMarker>
          {isSingleLocation ? fromLocation : to}
        </dd>
      </DefinitionList>
    </OverviewElement>
  );
};

export const Terrain = ({ terrain }) => {
  return (
    <OverviewElement>
      <DefinitionList>
        <dt>Terrain: </dt>
        <dd>{terrain}</dd>
      </DefinitionList>
    </OverviewElement>
  );
};

export const Weather = ({ weather }) => {
  return (
    <OverviewElement>
      <DefinitionList>
        <dt>Weather: </dt>
        <dd>
          <Icon>{weatherConditions[`${weather}`]}</Icon>
        </dd>
      </DefinitionList>
    </OverviewElement>
  );
};

export const Cargo = ({ weight, cargo }) => {
  const cargoWeight = getRaceCargoWeight(weight);

  return (
    <OverviewElement>
      <DefinitionList>
        <dt>Cargo: </dt>
        <dd>
          {cargoWeight}
          <Box
            as="span"
            pl={10}
            fontFamily="Lufga-Regular"
            color="#a8a8a8"
          >{`(${cargo})`}</Box>
        </dd>
      </DefinitionList>
    </OverviewElement>
  );
};

export const Distance = ({ distance }) => {
  return (
    <OverviewElement>
      <DefinitionList>
        <dt>Distance: </dt>
        <dd>{distance}km</dd>
      </DefinitionList>
    </OverviewElement>
  );
};

export const weatherConditions = {
  Rainy: <Rainy />,
  Sunny: <Sunny />,
  Stormy: <Stormy />,
  Windy: <Windy />,
  Foggy: <Foggy />,
  Snowy: <Snowy />,
  Icy: <Icy />,
};
