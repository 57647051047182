import Button from "../Button";

export default function RacingButton({ children, ...rest }) {
  return (
    <Button
      color="#fff"
      hoverColor="#feb806"
      border="1px solid #8A8A8A"
      hoverBorder="1px solid #feb806"
      borderRadius={4}
      zIndex={1}
      {...rest}
    >
      {children}
    </Button>
  );
}
