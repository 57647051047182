import { useRouter } from "next/router";
import { Box } from "@xstyled/styled-components";
import Button from "@/UI/Button";
import { Container, FlexBox } from "@/UI/Containers";
import { LandingTitle, Text } from "@/UI/Text";
import Icon from "@/UI/Icon";
import {
  DiscordOrange,
  QuestionmarkOrange,
  SupplainOrange,
  WofOrange,
  GarageOrange,
} from "@/UI/icons";

export default function Benefits() {
  const router = useRouter();

  return (
    <Box
      as="section"
      id="benefits"
      position="relative"
      background="#05151b"
      backgroundImage="linear-gradient(180deg, rgba(5,21,27,1) 0%, rgba(27,34,49,1) 100%)"
      overflow="hidden"
    >
      <Container
        p={{ _: "0 1rem 2rem 1rem", desktop: "5rem 0 5rem 0" }}
        zIndex={1}
        data-aos="fade-up"
      >
        <FlexBox dir="column" justify="flex-start" align="flex-start">
          <Text mb="1.5rem" size="1.25rem" font="Regular">
            WHAT ELSE DOES YOUR NFT GIVE YOU?
          </Text>
          <LandingTitle
            maxW={700}
            mt={0}
            lineHeight={1.2}
            text="All world of freight vehicles come with special perks"
          />
          <FlexBox
            gap={{ _: 10, desktop: 20 }}
            wrap={{ _: "wrap", xl_desktop: "nowrap " }}
            justify="center"
          >
            {perks.map((perk) => {
              return (
                <Button
                  key={perk.name}
                  onClick={() => router.push(perk.path)}
                  dir="column"
                  h={{ _: 210, desktop: 340 }}
                  w={{ _: 172, desktop: 248 }}
                  py={{ _: 5, desktop: 60 }}
                  px={18}
                  bg="#292F3C"
                  borderRadius={5}
                  hoverTransform={{ desktop: "translateY(-3%)" }}
                  transition="0.4s all ease-in-out"
                >
                  <Icon
                    aria={perk.name}
                    transform={{
                      _: "scale(0.65) ",
                      desktop: "scale(0.85) ",
                    }}
                    display="block"
                    p={10}
                    h={{ _: 60, desktop: 80 }}
                  >
                    {perk.icon}
                  </Icon>
                  <Text
                    size="1.5rem"
                    font="Medium"
                    color={{ _: "#FEB806", desktop: "white" }}
                    lineHeight={{ desktop: "2" }}
                    m={0}
                  >
                    {perk.name}
                  </Text>

                  <Text
                    max={{ desktop: 165 }}
                    size="1rem"
                    font="Regular"
                    color="#fff"
                    lineHeight="1.2"
                  >
                    {perk.about}
                  </Text>
                </Button>
              );
            })}
          </FlexBox>
        </FlexBox>
      </Container>
    </Box>
  );
}

const perks = [
  {
    icon: <GarageOrange />,
    name: "Garage",
    path: "/garage",
    about: "Customize & upgrade your vehicles at the Garage.",
  },
  {
    icon: <WofOrange />,
    name: "$WOF",
    path: "/wof-token",
    about: "Each vehicle earns 25 $WOF Utility Tokens per day.",
  },
  {
    icon: <SupplainOrange />,
    name: "Supplain",
    path: "https://supplain.io/",
    about: "Help build a new supply chain blockchain with us.",
  },
  {
    icon: <DiscordOrange />,
    name: "Community",
    path: "https://discord.gg/WorldOfFreight",
    about: "Awesome community of freight frens full of alpha.",
  },
  {
    icon: <QuestionmarkOrange />,
    name: "Future",
    path: "https://worldoffreight.gitbook.io/world-of-freight/",
    about: "NFT leasing, future AirDrops & much more.",
  },
];
