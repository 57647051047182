import { useRouter } from "next/router";
import { Box } from "@xstyled/styled-components";
import Slider from "@/landing-v3/upcoming-races/Slider";
import Button from "@/UI/Button";
import { Container, FlexBox } from "@/UI/Containers";
import ClientOnly from "@/UI/ClientOnly";
import { GoldenCalix } from "@/UI/icons";
import Icon from "@/UI/Icon";

export default function UpcomingRaces({ upcomingRaces }) {
  const router = useRouter();

  return (
    <ClientOnly
      as="section"
      position="relative"
      h={380}
      background="#05151b"
      backgroundImage="linear-gradient(180deg, rgba(5,21,27,1) 0%, rgba(1,16,26,1) 60%, rgba(31,38,54,1) 100%)"
      overflowX="hidden"
    >
      <Container
        maxW={{ desktop: "100%" }}
        ml={{
          desktop: "5%",
          xl_desktop: "7%",
          xxl_desktop: "14%",
        }}
        zIndex={1}
      >
        <FlexBox dir="column" align="flex-start">
          <FlexBox m="0 0 20px 10px">
            <Icon aria="Golden calix" mr={16}>
              <GoldenCalix />
            </Icon>
            <Box as="h2" fontSize="1.25rem" fontFamily="Lufga-SemiBold">
              Upcoming Races
            </Box>
          </FlexBox>
          <Slider races={upcomingRaces} />
        </FlexBox>
      </Container>
      <FlexBox>
        <Button
          onClick={() => router.push("/racing-arena/upcoming")}
          m={{ _: "180px auto 0", desktop: "56px auto 0" }}
          color="#fff"
          textDecoration="underline"
          hoverColor="#FEB806"
        >
          More Races
        </Button>
      </FlexBox>
    </ClientOnly>
  );
}
