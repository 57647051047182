import { transportationModes } from "src/data/racing";
import styled from "@xstyled/styled-components";
import { FlexBox } from "@/UI/Containers";

const IconWrapper = styled.span`
  padding-right: 10;
  padding-top: 3;

  svg path,
  svg rect {
    fill: ${({ hovered, activeFilter, color }) =>
      activeFilter || hovered ? "black" : color};
  }
`;

export default function RaceBadge({
  raceClass: raceType,
  hovered,
  activeFilter,
  ...rest
}) {
  const getRaceClass = () => {
    switch (raceType.toLowerCase()) {
      case "water":
        return transportationModes[0];
      case "air":
        return transportationModes[1];
      case "ground":
        return transportationModes[2];
      case "space":
        return transportationModes[3];
      default:
    }
  };

  const raceClass = getRaceClass();

  return (
    <FlexBox
      as="span"
      h={{ _: 30, desktop: 34 }}
      w="max-content"
      px={20}
      fontSize={{ _: "0.875rem", desktop: "1rem" }}
      bg="#1F2533"
      color={activeFilter || hovered ? "black" : raceClass?.color}
      borderRadius={17}
      {...rest}
    >
      <IconWrapper
        aria={raceClass?.class}
        activeFilter={activeFilter}
        color={raceClass?.color}
        hovered={hovered}
      >
        {raceClass?.icon}
      </IconWrapper>
      {raceClass?.class}
    </FlexBox>
  );
}
