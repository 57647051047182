import { useRouter } from "next/router";
import WidgetBot from "@widgetbot/react-embed";
import { useAtomValue, useSetAtom } from "jotai";
import { userFleetAtom, userWalletAtom } from "src/store";
import { walletModalAtom } from "src/store/modals";
import { DISCORD_CHANNEL, DISCORD_SERVER } from "src/constants";
import styled, { Box } from "@xstyled/styled-components";
import Navbar from "@/UI/header/Navbar";
import { Text } from "@/UI/Text";
import { Container, FlexBox } from "@/UI/Containers";
import GradientButtonYellow from "@/UI/buttons/GradientButtonYellow";
import ClientOnly from "@/UI/ClientOnly";

const HeroWrapper = styled.sectionBox`
  width: 100vw;
  height: 100%;
  background-image: url("/assets/backgrounds/bg_hero.png");
  background-repeat: no-repeat;
  background-position: center top 5%;
  background-size: 270%;

  @media (min-width: tablet) {
    background-position: center top 0;
    background-size: 170%;
  }

  @media (min-width: desktop) {
    background-position: top center;
    background-size: cover;
  }

  @media (min-width: xxl_desktop) {
    background-position: 0 -50px;
  }
`;

const Title = styled(Text)`
  font-family: "Lufga-SemiBold";
  font-size: 29px;
  line-height: 1.2;
  margin-top: 6rem;
  margin-bottom: 1rem;
  text-align: center;
  width: 180px;

  @media (min-width: sm_tablet) {
    width: 100%;
  }

  @media (min-width: desktop) {
    font-size: 48px;
    margin-top: 8rem;
  }
`;

const Media = styled.divBox`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 3rem;
  overflow: hidden;

  @media (min-width: desktop) {
    flex-direction: row;
    margin-top: 6rem;
  }

  [title="Discord chat embed"] {
    height: 350px !important;
  }
`;

export default function HomeHero() {
  const router = useRouter();

  const { address } = useAtomValue(userWalletAtom);
  const fleet = useAtomValue(userFleetAtom);
  const setShowWalletModal = useSetAtom(walletModalAtom);

  return (
    <ClientOnly>
      <Navbar position="absolute" w="100%" />
      <HeroWrapper data-aos="fade" bg="#05151B">
        <Container
          data-aos="fade-down"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          pt={140}
          pb={0}
        >
          <Title as="h1">VEHICLES. RACING. AND MORE.</Title>
          <Text size="1rem" font="Regular" max={850} textAlign="center" m={0}>
            Own a digital vehicle. Join the P2E game. Build supply chain
            infrastructure.
          </Text>
          <GradientButtonYellow
            onClick={() => {
              if (fleet) {
                window.fbq("trackCustom", "start");
                router.push("/racing-arena/upcoming");
              } else {
                setShowWalletModal(true);
              }
            }}
            mt="1.5rem"
            p="26px 42px"
            letterSpacing="2.3"
          >
            START
          </GradientButtonYellow>
          <Media spaceX={{ desktop: "1rem" }}>
            <Box
              as="video"
              autoPlay={true}
              muted
              playsInline
              loop
              position="relative"
              width="100%"
              maxW={640}
              maxH={360}
              borderRadius={10}
            >
              <source
                src="/assets/video/wof_web_promo.mp4"
                type="video/mp4"
                alt="WOF promo clip"
              />
            </Box>
            {address && (
              <FlexBox
                w={{ _: "100%", desktop: 500 }}
                mt={{ _: "1rem", desktop: 0 }}
                justify="center"
              >
                <WidgetBot
                  server={DISCORD_SERVER}
                  channel={DISCORD_CHANNEL}
                  width="100%"
                />
              </FlexBox>
            )}
          </Media>
        </Container>
      </HeroWrapper>
    </ClientOnly>
  );
}
