import { useState, useEffect } from "react";
import { useAtomValue } from "jotai";
import { userFleetAtom } from "src/store";
import { FlexBox } from "@/UI/Containers";
import { Text } from "@/UI/Text";
import Button from "@/UI/Button";

export default function IntroPunk() {
  const [isDismissed, setIsDismissed] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const userFleet = useAtomValue(userFleetAtom);

  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > 1000) setIsVisible(true);
  };

  useEffect(() => {
    if (!userFleet && !isDismissed) {
      window.addEventListener("scroll", listenToScroll);
      return () => window.removeEventListener("scroll", listenToScroll);
    }
  }, [userFleet, isDismissed]);

  return isVisible ? (
    <FlexBox
      w={400}
      h={280}
      position="fixed"
      bottom={{ _: -30, sm_desktop: 0 }}
      right={{ _: -50, sm_desktop: 0 }}
      zIndex={5}
      transform={{ _: "scale(0.8)", sm_desktop: "scale(1)" }}
    >
      <Text
        w={180}
        position="absolute"
        top={0}
        left={12}
        color="white"
        fontFamily="Lufga-Medium"
        size="0.875rem"
      >
        Are you new here and want to know more about WOF ecosystem?
      </Text>
      <PopupButton
        text="Not now"
        onClick={() => {
          setIsVisible(false);
          setIsDismissed(true);
        }}
        left={12}
        p="6px 10px"
        color="white"
        hoverBackground="white"
        hoverColor="#22272B"
      />
      <PopupButton
        text="Yes"
        as="a"
        href="https://worldoffreight.gitbook.io/world-of-freight"
        target="_blank"
        rel="noreferrer"
        left={120}
        p="6px 30px"
        color="#22272B"
        bg="white"
        hoverBackground="transparent"
        hoverColor="white"
      />
      <img
        alt="Truck driver with speech bubble"
        src="/assets/camionero_chat.png"
        height={280}
      />
    </FlexBox>
  ) : null;
}

const PopupButton = ({ text, ...rest }) => {
  return (
    <Button
      position="absolute"
      top={85}
      fontFamily="Lufga-Medium"
      size="0.875rem"
      border="1px solid white"
      borderRadius={5}
      {...rest}
    >
      {text}
    </Button>
  );
};
