import { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box } from "@xstyled/styled-components";
import RaceBox from "./RaceBox";
import { FlexBox } from "@/UI/Containers";
import Button from "@/UI/Button";
import Icon from "@/UI/Icon";
import { OrangeArrow } from "@/UI/icons/Landing";

export default function Slider({ races }) {
  const sliderRef = useRef(null);

  const [arrows, setArrows] = useState({ left: false, right: true });

  return (
    <FlexBox w="100%" display="block" top={80}>
      <Box position="relative" top={-14} right={2} zIndex={100}>
        {arrows.left && (
          <Arrow
            dir="left"
            right={30}
            pb={2}
            onClick={() => sliderRef.current?.swiper.slidePrev(500)}
          />
        )}
        {arrows.right && (
          <Arrow
            dir="right"
            right={-10}
            onClick={() => sliderRef.current?.swiper.slideNext(500)}
          />
        )}
      </Box>
      <FlexBox w="100%" position="absolute">
        <Swiper
          ref={sliderRef}
          breakpoints={{
            0: {
              spaceBetween: 180,
              slidesPerView: 1,
            },
            480: {
              spaceBetween: 240,
              slidesPerView: 2,
            },
            670: {
              spaceBetween: 70,
              slidesPerView: 2,
            },
            992: {
              spaceBetween: 100,
              slidesPerView: 3,
            },
            1420: {
              spaceBetween: 380,
              slidesPerView: races?.length < 4 ? races?.length : 4,
            },
            1920: {
              spaceBetween: 160,
              slidesPerView: races?.length < 4 ? races?.length : 4,
            },
          }}
          onFromEdge={() => {
            setArrows({ left: true, right: true });
          }}
          onReachBeginning={() => {
            setArrows({ left: false, right: true });
          }}
          onReachEnd={() => {
            setArrows({ left: true, right: false });
          }}
        >
          {races?.map((race) => {
            return (
              <SwiperSlide key={race.id}>
                <RaceBox race={race} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </FlexBox>
    </FlexBox>
  );
}

const Arrow = ({ dir, onClick, ...rest }) => {
  return (
    <Button
      onClick={onClick}
      display={{ desktop: "none" }}
      justify="center"
      w={55}
      h={50}
      bg="transparent"
      position="absolute"
      top={-60}
      hoverOpacity={0.5}
      cursor="pointer"
      zIndex={10}
      {...rest}
    >
      <Icon aria="Carousel arrows" transform="translateY(10%)">
        <Box
          transform={dir === "left" ? "rotate(180deg)" : "initial"}
          pt={dir === "left" ? 0 : 7}
        >
          <OrangeArrow />
        </Box>
      </Icon>
    </Button>
  );
};
