import { Boat, Chopper, Racecar, Spaceship } from "@/UI/icons";

export const racePages = [
  {
    title: "Upcoming Races",
    path: "upcoming",
    query: "upcoming",
  },
  {
    title: "Next To Race",
    path: "next-to-race",
    query: "next-to-race",
  },
  {
    title: "Results",
    path: "results",
    query: "archived",
  },
  {
    title: "My Area",
    path: "my-area",
    query: "my-area",
  },
  {
    title: "Leaderboard",
    path: "leaderboard",
    query: "leaderboard",
  },
];

export const leaderTableColumns = ({ sortBy, viewportWidth }) => {
  return [
    {
      Header: "",
      accessor: "position",
    },
    {
      Header: "",
      accessor: "username",
    },
    {
      Header: viewportWidth >= 1024 ? "Wins / Podiums / Races" : "W / P / R",
      accessor: "podium_places",
    },
    {
      Header: "Tokens won",
      accessor: "tokens_won",
    },
    {
      Header: sortBy === "user" ? "Points" : "Races won",
      accessor: sortBy === "user" ? "points" : "races_won",
    },
  ];
};

export const raceGradients = [
  {
    type: "ground",
    from: "rgba(4,26,36,0.22)",
    to: "rgba(72,152,99,0.22)",
  },
  {
    type: "air",
    from: "rgba(81,87,86,0)",
    to: "rgba(97,97,97,0.66)",
  },
  {
    type: "space",
    from: "rgba(128,14,14,0)",
    to: "rgba(255,0,0,0.22)",
  },
  {
    type: "water",
    from: "rgba(4,26,36,0.22)",
    to: "rgba(72,107,152,0.22)",
  },
];

export const raceContestants = [
  { position: 1, id: "racer-1", color: "#ACD6EE" },
  { position: 2, id: "racer-2", color: "#7796A8" },
  { position: 3, id: "racer-3", color: "#4F6B7C" },
  { position: 4, id: "racer-4", color: "#4F7C76" },
  { position: 5, id: "racer-5", color: "#88BCB5" },
  { position: 6, id: "racer-6", color: "#93E4D9" },
  { position: 7, id: "racer-7", color: "#A0E493" },
  { position: 8, id: "racer-8", color: "#79A571" },
  { position: 9, id: "racer-9", color: "#3D5B37" },
  { position: 10, id: "racer-10", color: "#6F6C4D" },
  { position: 11, id: "racer-11", color: "#BDB885" },
  { position: 12, id: "racer-12", color: "#F0E79D" },
];

export const transportationModes = [
  {
    class: "Water",
    icon: <Boat />,
    color: "#87DEFF",
    vehicles: [{ name: "Cargo Ship", value: "Cargo Ship" }],
  },
  {
    class: "Air",
    icon: <Chopper />,
    color: "#ACACAC",
    vehicles: [
      { name: "Aircraft", value: "Freight aircraft" },
      { name: "Drone", value: "Drone" },
      { name: "Hot-air balloon", value: "Hot-air balloon" },
      { name: "Zeppelin", value: "Airship / Zeppelin" },
    ],
  },
  {
    class: "Ground",
    icon: <Racecar />,
    color: "#11D552",
    vehicles: [
      { name: "Box Truck", value: "Box Truck" },
      { name: "Delivery Robot", value: "Delivery Robot" },
      { name: "Semi Truck", value: "Semi Truck" },
      { name: "Train", value: "Locomotive / Train" },
      { name: "Van", value: "Van" },
    ],
  },
  {
    class: "Space",
    icon: <Spaceship />,
    color: "#BD2F50",
    vehicles: [
      { name: "Space Launcher", value: "Space Launcher" },
      { name: "Spaceplane", value: "Spaceplane" },
      { name: "UFO", value: "Flying Saucer (UFO)" },
      { name: "X-Wing", value: "X-Wing" },
    ],
  },
];

export const vehicleTraitSortOptions = (dir) => {
  return [
    {
      label: "By Emission Rate",
      value: { attributes: { emission_rate: dir } },
    },
    {
      label: "By Fuel Efficiency",
      value: { attributes: { fuel_efficiency: dir } },
    },
    {
      label: "By Max Capacity",
      value: { attributes: { max_capacity: dir } },
    },
    { label: "By Max Range", value: { attributes: { max_range: dir } } },
    { label: "By Max Speed", value: { attributes: { max_speed: dir } } },
    { label: "By Wins", value: { wins: dir } },
    { label: "By Podiums", value: { podium_places: dir } },
    { label: "By Races raced", value: { races_raced: dir } },
  ];
};
