import { useRouter } from "next/router";
import parseRace from "src/utils/racing/parseRace";
import { Box } from "@xstyled/styled-components";
import RaceBadge from "@/racing-arena/RaceBadge";
import { FlexBox, GridBox } from "@/UI/Containers";
import RacingButton from "@/UI/buttons/RacingButton";
import Icon from "@/UI/Icon";
import { Text } from "@/UI/Text";
import { TokenLogo } from "@/UI/icons";
import { weatherConditions } from "@/racing-arena/grid/tabs/overview/OverviewListItems";

const gradients = {
  ground: "rgba(72,152,99,0.44)",
  air: "rgba(92,104,112,0.44)",
  space: "rgba(255,0,0,0.44)",
  water: "rgba(72,107,152,0.44)",
};

export default function RaceBox({ race, ...rest }) {
  const router = useRouter();

  const { distance, prize_pool, participantsCount } = parseRace(race);

  return (
    <FlexBox
      dir="column"
      align="flex-start"
      w={{ _: 350, lg_desktop: 420 }}
      p={{ _: "18px 14px", lg_desktop: 22 }}
      background="rgb(12,30,41)"
      backgroundImage={`linear-gradient(300deg, rgba(12,30,41,0.44) 0%, ${
        gradients[`${race.class}`]
      } 100%)`}
      borderRadius={16}
      {...rest}
    >
      <FlexBox dir="column" align="flex-start" justify="space-between" w="100%">
        <FlexBox justify="space-between" w="100%">
          <Text
            m={0}
            max={{ _: "95%", desktop: "85%" }}
            size="1.125rem"
            font="Bold"
            whiteSpace="nowrap"
          >
            {race.name}
          </Text>
          <Text m={0} size={14}>
            {distance}
          </Text>
        </FlexBox>

        <FlexBox
          justify="space-between"
          w="100%"
          my={12}
          spaceX={16}
          whiteSpace="nowrap"
        >
          <RaceBadge raceClass={race.class} ml={-6} fontSize="0.875rem" />
          <FlexBox spaceX={4}>
            <Icon aria="WOF Token" transform="scale(0.9) translateY(5%)">
              <TokenLogo fill="#fff" />
            </Icon>
            <span>{prize_pool}</span>
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <FlexBox align="flex-end" justify="space-between" w="100%" pt={6}>
        <FlexBox
          dir="column"
          align="flex-start"
          justify="flex-end"
          h={80}
          spaceY={8}
          color="#A8A8A8"
        >
          {race.terrain && (
            <GridBox cols="50px 1fr">
              <Box as="dt">Terrain:</Box>
              <Box as="dd">{race.terrain}</Box>
            </GridBox>
          )}

          <GridBox cols="50px 1fr" position="relative">
            <Box as="dt">Weather:</Box>
            <Box as="dd" position="absolute" left="100%">
              <Icon aria="Weather">{weatherConditions[`${race.weather}`]}</Icon>
            </Box>
          </GridBox>

          <GridBox cols="50px 1fr">
            <Box as="dt">Cargo:</Box>
            <Box as="dd">{race.weight / 1000}t</Box>
          </GridBox>
        </FlexBox>

        {participantsCount === 8 ? (
          <Box as="span" mr={20} color="#A2ADB5">
            Full
          </Box>
        ) : (
          <RacingButton
            p="14px 12px"
            hoverColor="white"
            hoverBg="#8B95AB"
            border="1px solid #fff"
            hoverBorder="1px solid #8B95AB"
            onClick={() => {
              router.push(`/racing-arena/upcoming?race=${race.id}`);
            }}
          >
            Join
          </RacingButton>
        )}
      </FlexBox>
    </FlexBox>
  );
}
