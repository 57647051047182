import { useRef } from "react";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import styled, { Box } from "@xstyled/styled-components";

SwiperCore.use([Autoplay]);

const Slider = styled.divBox`
  .swiper-wrapper {
    transition-timing-function: linear;
  }
`;

export default function NFTSlider() {
  const sliderRef = useRef(null);

  return (
    <Slider maxW="100%" position="absolute" pt={30}>
      <Fade deg={90} />
      <Swiper
        ref={sliderRef}
        spaceBetween={5}
        breakpoints={{
          0: {
            slidesPerView: 2,
          },
          420: {
            slidesPerView: 3,
          },
          640: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 5,
          },
          992: {
            slidesPerView: 4,
          },
          1280: {
            slidesPerView: 5,
          },
          1420: {
            slidesPerView: 6,
          },
        }}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
        }}
        speed={6000}
        grabCursor
        loop
      >
        {data?.map(({ img }) => {
          return (
            <SwiperSlide key={img}>
              <Box
                as="img"
                src={img}
                alt="Image of a Wof NFT"
                w={{ _: 135, sm_mobile: 145, desktop: 205 }}
                h={{ _: 130, sm_mobile: 140, desktop: 201 }}
                borderRadius={4}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Fade deg={270} right={0} top={0} />
    </Slider>
  );
}

const Fade = ({ deg, ...rest }) => {
  return (
    <Box
      w="20%"
      h="100%"
      display={{ _: "none", sm_tablet: "flex" }}
      position="absolute"
      backgroundImage={`linear-gradient(${deg}deg, rgba(5,21,27,1) 0%, rgba(5,21,27,0) 100%)`}
      zIndex={2}
      {...rest}
    />
  );
};

const data = [
  {
    img: "/assets/landing-slider/nft_1.png",
  },
  {
    img: "/assets/landing-slider/nft_2.png",
  },
  {
    img: "/assets/landing-slider/minting_is_now_open.png",
  },
  {
    img: "/assets/landing-slider/nft_3.png",
  },
  {
    img: "/assets/landing-slider/nft_4.png",
  },
  {
    img: "/assets/landing-slider/nft_5.png",
  },
  {
    img: "/assets/landing-slider/nft_6.png",
  },
  {
    img: "/assets/landing-slider/nft_7.png",
  },
  {
    img: "/assets/landing-slider/nft_8.png",
  },
  {
    img: "/assets/landing-slider/nft_9.png",
  },
  {
    img: "/assets/landing-slider/nft_10.png",
  },
  {
    img: "/assets/landing-slider/nft_11.png",
  },
  {
    img: "/assets/landing-slider/nft_12.png",
  },
  {
    img: "/assets/landing-slider/nft_13.png",
  },
  {
    img: "/assets/landing-slider/nft_14.webp",
  },
  {
    img: "/assets/landing-slider/nft_15.png",
  },
];
