import dayjs from "dayjs";
import formatNumber from "../formatNumber";

export default function parseRace(race) {
  const raceDistance = parseInt(race.distance) / 1000;
  const distanceInKm = (raceDistance * 100) / 100;
  const distance = `${distanceInKm.toFixed(3)}km`;

  const entry_fee = formatNumber(race.entry_fee);
  const prize_pool = formatNumber(race.prize_pool);

  const date = race?.start ? dayjs(race.start).format("YYYY-MM-DD") : null;
  const time = race?.start ? dayjs(race.start).format("HH:mm") : null;

  const participantsCount = race.participants_aggregate.aggregate.count;

  return { distance, date, time, entry_fee, prize_pool, participantsCount };
}
