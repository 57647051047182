export const milestones = [
  {
    percent: "10%",
    done: true,
    text: "We create a Racing Arena with a Garage & Junkyard. Plus, the first 2500 NFT Hodlers get 500 extra WOF Tokens.",
  },
  {
    percent: "20%",
    done: true,
    text: "We release the Freight Punks. They come with special powers, joining only the Vehicles that have been minted by Nov 30th.",
  },
  {
    percent: "40%",
    done: false,
    text: "Members-Exclusive Merch Store gets unlocked, featuring Limited Edition hats, hoodies, and other goodies.",
  },
  {
    percent: "60%",
    done: false,
    text: "The 3D Converter Machine gets fixed. And the Racing Arena enters a new dimension.",
  },
  {
    percent: "80%",
    done: false,
    text: "We find a long lost map leading the way to an abandoned warehouse. Is it time for a treasure hunt? Someone mentioned Tesla Model 3, but that cannot be all...",
  },
  {
    percent: "90%",
    done: false,
    text: "Invites to an IRL event are sent out.",
  },
  {
    percent: "100%",
    done: false,
    text: "A Portal opens, and in come some odd-looking Vehicles. Are you ready?",
  },
];
