import { useRouter } from "next/router";
import { Box } from "@xstyled/styled-components";
import NFTSlider from "./NFTSlider";
import Button from "@/UI/Button";
import GradientButtonBlue from "@/UI/buttons/GradientButtonBlue";
import { Container, FlexBox } from "@/UI/Containers";
import { Text } from "@/UI/Text";

export default function NFTs() {
  const router = useRouter();

  return (
    <Box
      as="section"
      id="nft"
      position="relative"
      background="#05151b"
      overflow="hidden"
    >
      <Container
        data-aos="fade-up"
        p={{ _: "0 1rem 5rem", desktop: "0 0 5rem 0" }}
        zIndex={1}
      >
        <FlexBox dir="column" justify="center">
          <Box
            as="img"
            src="/assets/blue_light.png"
            mt={{
              _: -70,
              lg_mobile: -75,
              sm_tablet: -100,
              tablet: -160,
              desktop: -200,
              lg_desktop: -220,
              xl_desktop: -260,
            }}
            zIndex={-3}
          />
          <Box
            maxW={1226}
            h={{ _: 440, desktop: 429 }}
            border={{ desktop: "1px solid #292F3C" }}
            borderRadius={5}
          >
            <Text
              max={850}
              size="1.25rem"
              fontFamily={{ _: "Lufga-Medium", desktop: "Lufga-Bold" }}
              mt="3rem"
              textAlign="center"
            >
              At the World of Freight NFT ecosystem, everything revolves around
              the collection of 10,000 unique vehicle-themed NFTs. Get yours
              today!
            </Text>
          </Box>
          <NFTSlider />
          <FlexBox dir="column" p="0 20px" mt={-30} background="#05151b">
            <Text
              size={{ _: "1rem", desktop: "1.25rem" }}
              fontFamily={{ _: "Lufga-Medium", desktop: "Lufga-Regular" }}
              textAlign="center"
            >
              MINT YOURSELF A VEHICLE FOR 0.08 ETH
            </Text>
            <GradientButtonBlue
              onClick={() => router.push("/mint")}
              p="20px 40px 20px 48px"
              letterSpacing={6}
              fontFamily="Lufga-Semibold"
              fontSize="1rem"
            >
              MINT
            </GradientButtonBlue>
            <Button
              onClick={() =>
                router.push("https://opensea.io/collection/world-of-freight")
              }
              m="32px auto -12px"
              p="12px 48px"
              color="#fff"
              hoverColor="#FEB806"
              textDecoration="underline"
            >
              Or check out existing vehicles
            </Button>
          </FlexBox>
        </FlexBox>
      </Container>
    </Box>
  );
}
