import { apolloClient } from "src/lib/apollo";
import { UPCOMING_RACES } from "src/graphql/queries";
import { Box } from "@xstyled/styled-components";
import Hero from "@/landing-v3/Hero";
import UpcomingRaces from "@/landing-v3/upcoming-races";
import NFTs from "@/landing-v3/NFTs";
import Benefits from "@/landing-v3/Benefits";
import RoadPlan from "@/landing-v3/RoadPlan";
import IntroPunk from "@/landing-v3/IntroPunk";
import Footer from "@/UI/footer";
import Modals from "@/UI/modals/MainModals";

export default function Home({ upcomingRaces }) {
  return (
    <>
  {/*<ins className="clickworkceb" data-bottom="50" data-left="30" data-cebtype="3"></ins>*/}
  <Box bg="#05151B">
      <Modals />
      <IntroPunk />
      <Hero />
      <UpcomingRaces upcomingRaces={upcomingRaces} />
      <NFTs />
      <Benefits />
      <RoadPlan />
      <Footer />
    </Box>
   </>
  );
}

export async function getServerSideProps() {
  const { data } = await apolloClient.query({ query: UPCOMING_RACES });
  return {
    props: { upcomingRaces: data?.races },
  };
}
