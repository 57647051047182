import { useEffect, useState } from "react";
import { Box } from "@xstyled/styled-components";

export default function ClientOnly({ children, ...rest }) {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => setHasMounted(true), []);

  if (!hasMounted) return null;

  return <Box {...rest}>{children}</Box>;
}
